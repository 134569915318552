import React, {useEffect, useState} from 'react'
import {MonacoEditor} from "./MonacoEditor";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import {RunPhp} from "./RunPhp";
import {PLay} from "./Play";
const init = `<?php
function foo() {
    echo "En foo()<br />\\n";
}

function bar($arg = '')
{
    echo "En bar(); el argumento era '$arg'.<br />\\n";
}

// Esta es una función de envoltura alrededor de echo
function hacerecho($cadena)
{
    echo $cadena;
}

$func = 'foo';
$func();        // Esto llama a foo()

$func = 'bar';
$func('prueba');  // Esto llama a bar()

$func = 'hacerecho';
$func('prueba');  // Esto llama a hacerecho()

?>
`
export const App = () => {
    const [edit, setEdit] = useState(init);
    const [run,setRun] = useState(false)
    const [code,setCode] = useState("")
    useEffect(() => {
       if(run) {
           setCode("")
           setRun(false)
           RunPhp({setCode,edit})
       }
    }, [run]);
    return (
      <div className="row">
          <MonacoEditor
              edit={edit}
              setEdit={setEdit}
              setCode={setCode}
          />
          <div className=" mt-3">
              <Card>
                  <Card.Body>
                      <Card.Text>
                          {code === "" ?
                              <Button variant="primary"> <PLay
                                  setRun={setRun}
                                  size={40}
                              /></Button>
                              :
                              <pre>
                        <div dangerouslySetInnerHTML={{__html:code}}/>
                     </pre>
                          }
                      </Card.Text>
                  </Card.Body>
              </Card>
          </div>
      </div>
  );
}
