import {PhpWeb} from "php-wasm/PhpWeb.mjs";

export const RunPhp = ({setCode,edit}) => {
    const php = new PhpWeb();
    php.addEventListener('output', (event) => {
        setCode((add)=>
            add + event.detail
        )
    });
    php.run(edit)
    php.refresh()
}
