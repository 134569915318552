import React, {useState} from 'react'
import {NavbarApp} from "./Navbar";
import {Outlet} from "react-router-dom";
import {Container} from "react-bootstrap";
import { ThemeContext } from './Contexts.js';
import {getPreferredTheme} from "./Theme";
export const Layout = () => {
    const [theme, setTheme] = useState(getPreferredTheme());
    return (
        <ThemeContext.Provider value={theme}>
            <NavbarApp
                setTheme={setTheme}
            />
            <Container fluid>
                <Outlet/>
            </Container>
        </ThemeContext.Provider>
    );
}
