import React from 'react';
import ReactDOM from 'react-dom/client';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ErrorPage from "./routes/ErrorPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Layout} from "./routes/Layout";
import {App} from './App';
import { Brain } from './Brain';

const router = createBrowserRouter([
    {
        path: "/",
        element:  <Layout/>,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                path: "brain",
                element: <Brain/>,
            },
            {
                path: "php-wasm",
                element: <App/>,
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ThemeProvider
          breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
          minBreakpoint="xxs"
      >
          <RouterProvider router={router} />
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
