import React from 'react'
import {Theme} from "./Theme";
import {Container, Nav,Navbar} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
export const NavbarApp = ({setTheme}) => {
    const expand = "md"
    return (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
            <Container fluid>
                <Navbar.Brand href="/#">myTest</Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            MyTest
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="me-auto">
                            <NavLink
                                to="/php-wasm"
                                className={({ isActive, isPending, isTransitioning }) => ((isActive ? "active" : "") + " nav-link")}>
                                php-wasm
                            </NavLink>
                            <NavLink
                                to="/brain"
                                className={({ isActive, isPending, isTransitioning }) => ((isActive ? "active" : "") + " nav-link")}>
                                brain.js
                            </NavLink>
                        </Nav>
                        <Nav><Theme setThemes={setTheme}/></Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    );
}
