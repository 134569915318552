import React, {useContext, useEffect, useRef} from "react";
import Editor from "@monaco-editor/react";
import {ThemeContext} from "../routes/Contexts";
export const MonacoEditor = ({setEdit,edit,setCode}) =>  {
    const editorRef = useRef(null);
    const theme = useContext(ThemeContext);
    useEffect(() => {
    }, [theme]);
    return (
        <Editor
            height="calc(50vh - 45px)"
            theme={theme === 'dark' ? "vs-dark" : '' }
            defaultLanguage={'php'}
            defaultValue={edit}
            onChange={(e)=>{
                setEdit(e)
                setCode("")
            }}
            onMount={(editor) => (editorRef.current = editor)}
            options={{
                scrollBeyondLastLine:false,
                fontSize:"14px"
            }}
        />
    );
}

