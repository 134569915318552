import Card from "react-bootstrap/Card";
import React, { useRef, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CanvasDraw from "react-canvas-draw";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
export const Brain = () => {
    const editorRef = useRef(null);
    const [chart,setChart] = useState('')
    const [saveData,setSaveData] = useState(false)
    const config = {
        loadTimeOffset: 5,
        lazyRadius: 30,
        brushRadius: 12,
        brushColor: "#000",
        gridColor: "rgba(150,150,150,1)",
        hideGrid: false,
        canvasWidth: 400,
        canvasHeight: 400,
        disabled: false,
        immediateLoading: false,
        hideInterface: false,
        gridSizeX: 25,
        gridSizeY: 25,
        gridLineWidth: 0.5,
        hideGridX: false,
        hideGridY: false,
        enablePanAndZoom: true,
        mouseZoomFactor: 0.01,
        zoomExtents: { min: 0.33, max: 3 },
    };
    return <Container fluid>
        <Row>
            <Col> <h1>Brain.js</h1>  </Col>
        </Row>
        <Row  className="justify-content-md-center">
            <Col xxl={'auto'} sm={'auto'}  className=" mt-3">
                <Card style={{overflow:'hidden'}}>
                    <CanvasDraw
                        ref={(editor) => (editorRef.current = editor)}
                        {...config}
                    />
                    <ButtonGroup  aria-label="First group">
                        <Form.Control
                            width={'100%'}
                            type="text"
                            placeholder="Name"
                            aria-label="Name"
                            aria-describedby="btnGroupAddon"
                            onChange={(e)=>{
                                setChart(e.target.value)
                            }}
                        />
                        <Button onClick={()=>{
                            editorRef.current.clear()
                            setSaveData(false)
                        }} variant="primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-eraser" viewBox="0 0 16 16">
                                <path
                                    d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293z"/>
                            </svg>
                        </Button>
                        <Button onClick={()=>{
                            editorRef.current.undo()
                        }} variant="primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-backspace" viewBox="0 0 16 16">
                                <path
                                    d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/>
                                <path
                                    d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
                            </svg>
                        </Button>
                        <Button onClick={()=>{
                            setSaveData(editorRef.current.getSaveData())
                        }} variant="primary">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-save" viewBox="0 0 16 16">
                                <path
                                    d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1z"/>
                            </svg>
                        </Button>
                    </ButtonGroup>
                </Card>
            </Col>
            <Col  className=" mt-3">
                <Card><Card.Body>
                    {
                        saveData ?
                           <>
                               <Card.Title>Draw & Save</Card.Title>
                               <Card.Title>{chart}</Card.Title>
                               <Card.Text style={{fontSize:12}}>
                                   {saveData}
                               </Card.Text>
                               <Button variant="primary">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor"
                                        className="bi bi-activity" viewBox="0 0 16 16">
                                       <path fillRule="evenodd"
                                             d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2"/>
                                   </svg></Button>
                           </>
                         :  <Card.Title>Draw & Save</Card.Title>
                    }
                </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
}
