import { useRouteError} from "react-router-dom";
import React, {useState} from "react";
import {NavbarApp} from "./Navbar";
import {getPreferredTheme} from "./Theme";

export default function ErrorPage() {
    const error = useRouteError();
    const [theme, setTheme] = useState(getPreferredTheme());
    console.error(error,theme);
    return (
        <div className="App">
            <NavbarApp
                setTheme={setTheme}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className="alert alert-primary" role="alert">
                            <div id="error-page">
                                <h1>Oops!</h1>
                                <p>Sorry, an unexpected error has occurred.</p>
                                <p>
                                    <i>{error.statusText || error.message}</i>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
